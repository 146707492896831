








































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import AlertResponseModel from "@/alerts-system/models/AlertResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection } from "@/shared/models";

@Component
export default class AlertsView extends mixins(AppSectionAccessMixin) {
  search = "";
  isConfirmDialog = false;
  deletedAlert: AlertResponseModel | null = null;

  get appSection(): AppSection {
    return AppSection.ALERT_SYSTEM;
  }

  get headers(): any {
    return [
      {
        text: this.$lang("alertsSystem.tableHeader.text"),
        align: "start",
        value: "text",
        width: "25%",
      },
      {
        text: this.$lang("alertsSystem.tableHeader.feature"),
        align: "start",
        value: "feature",
      },
      {
        text: this.$lang("alertsSystem.tableHeader.subFeatures"),
        align: "start",
        value: "subFeatures",
        width: "25%",
      },
      {
        text: this.$lang("alertsSystem.tableHeader.displayTime"),
        align: "start",
        value: "displayTime",
      },
      {
        text: this.$lang("alertsSystem.tableHeader.applications"),
        align: "start",
        value: "applications",
      },
      {
        text: this.$lang("alertsSystem.tableHeader.type"),
        align: "start",
        value: "details.type",
      },
      ...(this.hasEditAccess
        ? [
            {
              text: this.$lang("alertsSystem.tableHeader.activate"),
              value: "enabled",
              sortable: false,
              width: "5%",
            },
          ]
        : []),
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("alertsSystem.tableHeader.actions"),
              value: "actions",
              align: "end",
              sortable: false,
              width: "5%",
            },
          ]
        : []),
    ];
  }

  get isLoading(): boolean {
    return this.$store.state.alertsSystemStore.isLoading;
  }

  get alerts(): Array<AlertResponseModel> {
    return this.$store.state.alertsSystemStore.alerts;
  }

  get reportItems(): Array<string> {
    return this.currentUser.reportAccessByApp[this.applicationId] || [];
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("alertsSystem.titleForList")
    );
    this.$store.dispatch("loadAlerts");
  }

  handleClickByTableRow(item: AlertResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "alert_view",
      params: {
        alertId: String(item.id),
      },
    });
  }

  handleOpenConfirmDialog(item: AlertResponseModel) {
    this.isConfirmDialog = true;
    this.deletedAlert = item;
  }

  async handleDeleteAlert() {
    await this.$store.dispatch("deleteAlert", this.deletedAlert?.id);
    this.isConfirmDialog = false;
    this.deletedAlert = null;
  }

  handleVisibilityChange({ id, enabled }: AlertResponseModel) {
    this.$store.dispatch("changeAlertVisibility", {
      alertId: id,
      payload: { enabled },
    });
  }

  getApplicationsLabel(item: AlertResponseModel): string {
    return item.applications?.length
      ? item.applications.join(", ")
      : this.$lang("alertsSystem.tableHeader.wholeSystem");
  }

  getSubFeaturesLabel(item: AlertResponseModel): string {
    return item.subFeatures.length &&
      item.subFeatures.length !== this.reportItems.length
      ? item.subFeatures.join(", ")
      : this.$lang("alertsSystem.tableHeader.allReports");
  }

  getFeatureInLowerCase(item: AlertResponseModel): string {
    return item?.feature?.toLowerCase();
  }

  getDisplayTimeInLowerCase(item: AlertResponseModel): string {
    return item?.displayTime?.toLowerCase();
  }

  getDetailsType(item: AlertResponseModel): string {
    return (
      item?.details?.type?.slice(0, 1)?.toUpperCase() +
      item?.details?.type?.slice(1)
    );
  }
}
