import { Vue, Component } from "vue-property-decorator";

import { AppSection, CurrentUserModel } from "@/shared/models";

@Component
export default class AppSectionAccessMixin extends Vue {
  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get appSection(): AppSection {
    return this.$route.name as AppSection;
  }

  get hasCreateAccess(): boolean {
    return !!this.currentUser.createAccessEntities[
      this.applicationId
    ]?.includes(this.appSection);
  }

  get hasEditAccess(): boolean {
    return !!this.currentUser.editAccessEntities[this.applicationId]?.includes(
      this.appSection
    );
  }

  get hasDeleteAccess(): boolean {
    return !!this.currentUser.deleteAccessEntities[
      this.applicationId
    ]?.includes(this.appSection);
  }

  get hasFinishAccess(): boolean {
    return !!this.currentUser.finishAccessEntities[
      this.applicationId
    ]?.includes(this.appSection);
  }

  get hasValidateAccess(): boolean {
    return !!this.currentUser.validateAccessEntities[
      this.applicationId
    ]?.includes(this.appSection);
  }
}
