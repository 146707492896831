var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("alertsSystem.titleForList"))+" ")]),_c('v-data-table',{class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.alerts,"search":_vm.search,"loading":_vm.isLoading},on:{"click:row":function($event){return _vm.handleClickByTableRow($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","small":"","fab":"","to":{
              name: 'alert_new',
            }}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)]},proxy:true},{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"item.feature",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang(("alertsSystem." + (_vm.getFeatureInLowerCase(item)))))+" ")]}},{key:"item.subFeatures",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSubFeaturesLabel(item))+" ")]}},{key:"item.displayTime",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang(("alertsSystem." + (_vm.getDisplayTimeInLowerCase(item)))))+" ")]}},{key:"item.applications",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getApplicationsLabel(item))+" ")]}},{key:"item.details.type",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.details.type,"small":""}},[_vm._v(" "+_vm._s(_vm.getDetailsType(item))+" ")])]}},{key:"item.enabled",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleVisibilityChange(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenConfirmDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("alertsSystem.deleteHint")))])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.isConfirmDialog),callback:function ($$v) {_vm.isConfirmDialog=$$v},expression:"isConfirmDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("alertsSystem.deleteDialogHeading"))+" ")]),_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.$lang( "alertsSystem.deleteDialogContent", (_vm.deletedAlert && _vm.deletedAlert.text) || "" ))+" ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.isConfirmDialog = false}}},[_vm._v(" "+_vm._s(_vm.$lang("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.handleDeleteAlert}},[_vm._v(" "+_vm._s(_vm.$lang("delete"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }